import { API, Auth } from "aws-amplify";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config.json";

export default function CancellationComplete(props) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <CancellationCompleteComp {...props} navigate={navigate} params={params} />
  );
}

class CancellationCompleteComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.state = {
      api_name: config.stage,
      id: this.props.params.reservationId,
      reservation: {
        Reservation: {
          reservation_date: null,
          reservation_no: null,
          body_no: null,
          plate_no: null,
        },
        round_name: null,
        car_type_name: null,
        inspection_type_name: null,
      },
      request: {},
    };
    this.readReservation();
  }
  /*
  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = await user.signInUserSession.idToken.jwtToken;
    const myinit = await {
      headers: {
        Authorization: token,
      },
    };

    await this.setState({
      token: token,
      myinit: myinit,
    });
  };
*/
  readReservation = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(
      this.state.api_name,
      "/reservations/" + this.state.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.setState({
          reservation: r,
        });
      })
      .catch((e) => {
        toast.error("予約データの取得エラー");
      });
  };

  render() {
    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Toaster />
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約キャンセル</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>予約をキャンセルしました。</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約日</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.Reservation.reservation_date}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>ラウンド</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.round_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約番号</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.Reservation.reservation_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.car_type_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>検査の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.inspection_type_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>車体番号</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.Reservation.body_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>ナンバー</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.state.reservation.Reservation.plate_no}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/");
                        }}
                      >
                        トップページへ
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.navigate("/confirmation_list");
                        }}
                      >
                        他の予約を確認する
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </>
    );
  }
}
