import { API, Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant";
import config from "../config.json";

export default function ReceptionDate(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionDateComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ReceptionDateComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      api_name: config.stage,
      slot: [],
      date_round: this.cookies.date_round,
      loading: false,
    };
  }

  componentDidMount() {
    this.getSlot();
  }

  // 枠取得
  getSlot = async () => {
    this.setState({ loading: true });

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        site_id: this.cookies.site_id,
        car_type: this.cookies.car_type,
        inspection_type: this.cookies.inspection_type,
        //timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/reservations/slot", request)
      .then((r) => {
        this.setState({
          request: request,
          slot: r,
        });
      })
      .catch((e) => {
        toast.error("予約枠データの取得エラー");
      });

    this.setState({ loading: false });
  };

  nextOnClick = async (event) => {
    if (!this.state.date_round) {
      alert("時間枠を選択してください");
      return false;
    }
    this.setCookie("date_round", this.state.date_round);
    let date_round = this.state.date_round.split(".");
    this.setCookie("reservation_date", date_round[0]);
    this.setCookie("round", date_round[1]);
    this.props.navigate("/reception_car_num");
  };

  render() {
    console.log("render()");
    //console.log(this.state.date_round);
    //console.log(this.state.slot);

    const slot = this.state.slot;
    let slot_list = [];
    let reservation_date = "";
    for (let i in slot) {
      if (reservation_date != slot[i].reservation_date) {
        reservation_date = slot[i].reservation_date;
        slot_list[reservation_date] = [];
      }
      slot_list[reservation_date].push({
        round: slot[i].round,
        vacant_num: slot[i].vacant_num,
      });
    }

    let slot_html = [];
    let reservation_date_html = [];
    for (let reservation_date in slot_list) {
      let round_html = [];
      let round_list = slot_list[reservation_date];
      for (let i in round_list) {
        let label = "○";
        let round = round_list[i].round;
        let value = reservation_date + "." + round;
        let vacant_num = round_list[i].vacant_num;
        if (vacant_num <= 0) {
          label = "×";
        } else if (vacant_num <= Constant.TRIANGLE_NUM) {
          label = "△";
        }
        let label_class = "btn btn-block btn-outline-primary";
        let label_active_class =
          "btn btn-block btn-outline-primary focus active";
        if (vacant_num <= 0) {
          label_class = "btn btn-block btn-outline-danger";
          label_active_class = "btn btn-block btn-outline-danger focus active";
        }
        round_html.push(
          <div className="col-2">
            <div className="form-group">
              <div className="btn-group-toggle" data-toggle="buttons">
                <label
                  id="date_round_1_1"
                  className={
                    this.state.date_round == value
                      ? label_active_class
                      : label_class
                  }
                >
                  <input
                    type="radio"
                    name="date_round"
                    autoComplete="off"
                    value={value}
                    onChange={(event) => {
                      this.setState({
                        date_round: event.target.value,
                      });
                    }}
                    checked={this.state.date_round == value ? true : false}
                    disabled={vacant_num <= 0 ? true : false}
                  />
                  {label}
                </label>
              </div>
            </div>
          </div>
        );
      }
      reservation_date_html.push(
        <div className="row">
          <div className="col-2">{reservation_date}</div>
          {round_html}
        </div>
      );
    }
    // マルチュウ以外
    if (this.cookies.inspection_type != Constant.INSPECTION_TYPE_4) {
      slot_html.push(
        <>
          <div className="row">
            <div className="col-2">希望日</div>
            <div className="col-2">
              <label>1R</label>
            </div>
            <div className="col-2">
              <label>2R</label>
            </div>
            <div className="col-2">
              <label>3R</label>
            </div>
            <div className="col-2">
              <label>4R</label>
            </div>
            <div className="col-2">
              <label>5R</label>
            </div>
          </div>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={this.state.loading}
          />
          {reservation_date_html}
        </>
      );
      // マルチュウ
    } else {
      slot_html.push(
        <>
          <div className="row">
            <div className="col-2">希望日</div>
            <div className="col-2">
              <label></label>
            </div>
          </div>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={this.state.loading}
          />
          {reservation_date_html}
        </>
      );
    }

    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Toaster />
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約受付</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>空いている時間枠を選択してください。</label>
                    </div>
                  </div>
                  {slot_html}
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          if (this.cookies.car_type == Constant.CAR_TYPE_1) {
                            this.props.navigate("/reception_inspection_type");
                          } else {
                            this.props.navigate("/reception_car_type");
                          }
                        }}
                      >
                        戻る
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.nextOnClick}
                      >
                        次へ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </>
    );
  }
}
