import { API, Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant";
import config from "../config.json";

export default function ReceptionCarNumber(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionCarNumberComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ReceptionCarNumberComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      api_name: config.stage,
      body_no_1: this.cookies.body_no_1 ? this.cookies.body_no_1 : "",
      plate_no_1: this.cookies.plate_no_1 ? this.cookies.plate_no_1 : "",
      body_no_2: this.cookies.body_no_2 ? this.cookies.body_no_2 : "",
      plate_no_2: this.cookies.plate_no_2 ? this.cookies.plate_no_2 : "",
      body_no_3: this.cookies.body_no_3 ? this.cookies.body_no_3 : "",
      plate_no_3: this.cookies.plate_no_3 ? this.cookies.plate_no_3 : "",
    };
  }

  checkVacant = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        car_type: this.cookies.car_type,
        inspection_type: this.cookies.inspection_type,
        reservation_date: this.cookies.reservation_date,
        round: this.cookies.round,
        car_num: this.cookies.car_num,
      },
    };

    await API.get(this.state.api_name, "/reservations/vacant", request)
      .then((r) => {
        console.log(r);
        if (!r) {
          alert("予約に空きがありません。");
          this.props.navigate("/reception_date");
          return false;
        }
        this.setCookie(
          "body_no_1",
          this.state.body_no_1 ? this.state.body_no_1.padStart(4, "0") : ""
        );
        this.setCookie(
          "plate_no_1",
          this.state.plate_no_1 ? this.state.plate_no_1.padStart(4, "0") : ""
        );
        this.setCookie(
          "body_no_2",
          this.state.body_no_2 ? this.state.body_no_2.padStart(4, "0") : ""
        );
        this.setCookie(
          "plate_no_2",
          this.state.plate_no_2 ? this.state.plate_no_2.padStart(4, "0") : ""
        );
        this.setCookie(
          "body_no_3",
          this.state.body_no_3 ? this.state.body_no_3.padStart(4, "0") : ""
        );
        this.setCookie(
          "plate_no_3",
          this.state.plate_no_3 ? this.state.plate_no_3.padStart(4, "0") : ""
        );
        this.props.navigate("/reception_confirm");
      })
      .catch((e) => {
        toast.error("予約のチェックエラー");
      });
  };

  isShinki() {
    return (
      this.cookies.inspection_type == Constant.INSPECTION_TYPE_2 ||
      this.cookies.inspection_type == Constant.INSPECTION_TYPE_3
    );
  }

  nextOnClick = async (event) => {
    // 入力チェック
    // 1台目
    if (!this.state.body_no_1) {
      alert("(1台目)車体番号を入力してください。");
      return false;
    }
    if (!this.state.body_no_1.match(/^[0-9]{1,4}$/)) {
      alert("(1台目)車体番号を半角数字で入力してください。");
      return false;
    }
    if (!this.isShinki()) {
      if (!this.state.plate_no_1) {
        alert("(1台目)ナンバーを入力してください。");
        return false;
      }
      if (!this.state.plate_no_1.match(/^[0-9]{1,4}$/)) {
        alert("(1台目)ナンバーを半角数字で入力してください。");
        return false;
      }
    }
    // 2台目
    if (this.cookies.car_num >= Constant.CAR_NUM_2) {
      if (!this.state.body_no_2) {
        alert("(2台目)車体番号を入力してください。");
        return false;
      }
      if (!this.state.body_no_2.match(/^[0-9]{1,4}$/)) {
        alert("(2台目)車体番号を半角数字で入力してください。");
        return false;
      }
      if (!this.isShinki()) {
        if (!this.state.plate_no_2) {
          alert("(2台目)ナンバーを入力してください。");
          return false;
        }
        if (!this.state.plate_no_2.match(/^[0-9]{1,4}$/)) {
          alert("(2台目)ナンバーを半角数字で入力してください。");
          return false;
        }
      }
    }
    // 3台目
    if (this.cookies.car_num >= Constant.CAR_NUM_3) {
      if (!this.state.body_no_3) {
        alert("(3台目)車体番号を入力してください。");
        return false;
      }
      if (!this.state.body_no_3.match(/^[0-9]{1,4}$/)) {
        alert("(3台目)車体番号を半角数字で入力してください。");
        return false;
      }
      if (!this.isShinki()) {
        if (!this.state.plate_no_3) {
          alert("(3台目)ナンバーを入力してください。");
          return false;
        }
        if (!this.state.plate_no_3.match(/^[0-9]{1,4}$/)) {
          alert("(3台目)ナンバーを半角数字で入力してください。");
          return false;
        }
      }
    }
    // 予約可チェック
    this.checkVacant();
  };

  render() {
    console.log("render()");
    console.log(this.state);

    const car_number_html = [];
    car_number_html.push(
      <div className="row">
        <div className="col-12">
          <label>1台目</label>
        </div>
      </div>
    );
    car_number_html.push(
      <div className="row">
        <div className="col-4">
          <label>車体番号</label>
        </div>
        <div className="col-8">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="body_number_1"
              placeholder=""
              maxLength="4"
              value={this.state.body_no_1}
              onChange={(event) => {
                this.setState({
                  body_no_1: event.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
    if (
      this.cookies.inspection_type != Constant.INSPECTION_TYPE_2 &&
      this.cookies.inspection_type != Constant.INSPECTION_TYPE_3
    ) {
      car_number_html.push(
        <div className="row">
          <div className="col-4">
            <label>ナンバー</label>
          </div>
          <div className="col-8">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="plate_number_1"
                placeholder=""
                maxLength="4"
                value={this.state.plate_no_1}
                onChange={(event) => {
                  this.setState({
                    plate_no_1: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    if (this.cookies.car_num >= Constant.CAR_NUM_2) {
      car_number_html.push(
        <div className="row">
          <div className="col-12">
            <label>2台目</label>
          </div>
        </div>
      );
      car_number_html.push(
        <div className="row">
          <div className="col-4">
            <label>車体番号</label>
          </div>
          <div className="col-8">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="body_number_2"
                placeholder=""
                maxLength="4"
                value={this.state.body_no_2}
                onChange={(event) => {
                  this.setState({
                    body_no_2: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
      if (
        this.cookies.inspection_type != Constant.INSPECTION_TYPE_2 &&
        this.cookies.inspection_type != Constant.INSPECTION_TYPE_3
      ) {
        car_number_html.push(
          <div className="row">
            <div className="col-4">
              <label>ナンバー</label>
            </div>
            <div className="col-8">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="plate_number_2"
                  placeholder=""
                  maxLength="4"
                  value={this.state.plate_no_2}
                  onChange={(event) => {
                    this.setState({
                      plate_no_2: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    }
    if (this.cookies.car_num >= Constant.CAR_NUM_3) {
      car_number_html.push(
        <div className="row">
          <div className="col-12">
            <label>3台目</label>
          </div>
        </div>
      );
      car_number_html.push(
        <div className="row">
          <div className="col-4">
            <label>車体番号</label>
          </div>
          <div className="col-8">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="body_number_3"
                placeholder=""
                maxLength="4"
                value={this.state.body_no_3}
                onChange={(event) => {
                  this.setState({
                    body_no_3: event.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
      if (
        this.cookies.inspection_type != Constant.INSPECTION_TYPE_2 &&
        this.cookies.inspection_type != Constant.INSPECTION_TYPE_3
      ) {
        car_number_html.push(
          <div className="row">
            <div className="col-4">
              <label>ナンバー</label>
            </div>
            <div className="col-8">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="plate_number_3"
                  placeholder=""
                  maxLength="4"
                  value={this.state.plate_no_3}
                  onChange={(event) => {
                    this.setState({
                      plate_no_3: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約受付</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>
                        車体番号とナンバーの下4桁を入力してください。
                        ※軽自動車の新規の場合は、ナンバーは 0000
                        で入力してください。
                      </label>
                    </div>
                  </div>
                  {car_number_html}
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/reception_car_num");
                        }}
                      >
                        戻る
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.nextOnClick}
                      >
                        次へ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </>
    );
  }
}
