import { API, Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";

export default function Navbar(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <NavbarComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}
class NavbarComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      api_name: config.stage,
      member: {
        name: null,
        site_id: null,
      },
    };
    this.readMember();
  }
  /*
  componentDidMount() {
    console.log("componentDidMount");
    Auth.currentSession().then((data) => {
      this.setState({
        api_name: config.stage,
        user: data.idToken.payload.email,
      });
    });
  }
*/
  readMember = async () => {
    console.log("readMember");
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/members/info", request)
      .then((r) => {
        console.log(r);
        this.setState({
          member: r,
        });
        this.setCookie("site_id", r.site_id);
      })
      .catch((e) => {
        toast.error("会員データの取得エラー");
      });
  };

  render() {
    console.log("render()");
    return (
      <>
        <nav className="main-header navbar navbar-dark">
          <Toaster />
          <div className="col-12 text-center">
            <a href="/" className="navbar-brand">
              <span className="brand-text">車検予約システム</span>
            </a>
          </div>
        </nav>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Navbar */}
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                data-enable-remember="true"
                href="#"
                role="button"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            {/* Navbar Search */}
            <li className="nav-item">
              <a className="nav-link" data-widget="" href="#" role="button">
                {this.state.member.name}
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-sign-out-alt"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  className="dropdown-item dropdown-footer"
                  onClick={() => Auth.signOut()}
                >
                  ログアウト
                </button>
              </div>
            </li>
          </ul>
          {/* /.navbar */}
        </nav>
      </>
    );
  }
}
