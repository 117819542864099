import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant";

export default function ReceptionComplete(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionCompleteComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
      removeCookie={removeCookie}
    />
  );
}

class ReceptionCompleteComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.removeCookie = props.removeCookie;
    // リダイレクト
    if (!this.cookies.car_type) {
      this.props.navigate("/reception_car_type");
    }
  }

  componentDidMount() {
    // クッキー削除
    this.removeCookie("car_type");
    this.removeCookie("inspection_type");
    this.removeCookie("date_round");
    this.removeCookie("reservation_date");
    this.removeCookie("round");
    this.removeCookie("car_num");
    this.removeCookie("body_no_1");
    this.removeCookie("plate_no_1");
    this.removeCookie("body_no_2");
    this.removeCookie("plate_no_2");
    this.removeCookie("body_no_3");
    this.removeCookie("plate_no_3");
    this.removeCookie("reservatio_no_1");
    this.removeCookie("reservatio_no_2");
    this.removeCookie("reservatio_no_3");
  }

  render() {
    console.log("render()");
    console.log(this.state);

    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約受付</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>
                        予約を承りました。予約番号を確認してください。
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.CAR_TYPE_LIST[this.cookies.car_type]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>検査の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {
                            Constant.INSPECTION_TYPE_LIST[
                              this.cookies.inspection_type
                            ]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>希望日</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.cookies.reservation_date}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>ラウンド</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.ROUND_LIST[this.cookies.round]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>台数</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.CAR_NUM_LIST[this.cookies.car_num]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>車体番号|ナンバー</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          [1台目]{" "}
                          {this.cookies.body_no_1
                            ? this.cookies.body_no_1
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_1
                            ? this.cookies.plate_no_1
                            : "-"}
                        </div>
                        <div className="form-text">
                          [2台目]{" "}
                          {this.cookies.body_no_2
                            ? this.cookies.body_no_2
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_2
                            ? this.cookies.plate_no_2
                            : "-"}
                        </div>
                        <div className="form-text">
                          [3台目]{" "}
                          {this.cookies.body_no_3
                            ? this.cookies.body_no_3
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_3
                            ? this.cookies.plate_no_3
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約番号</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          [1台目]{" "}
                          {this.cookies.reservation_no_1
                            ? this.cookies.reservation_no_1
                            : "-"}
                        </div>
                        <div className="form-text">
                          [2台目]{" "}
                          {this.cookies.reservation_no_2
                            ? this.cookies.reservation_no_2
                            : "-"}
                        </div>
                        <div className="form-text">
                          [3台目]{" "}
                          {this.cookies.reservation_no_3
                            ? this.cookies.reservation_no_3
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/");
                        }}
                      >
                        トップページへ
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.navigate("/reception_car_type");
                        }}
                      >
                        続けて予約
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </>
    );
  }
}
