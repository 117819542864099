import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CancellationComplete from "./components/CancellationComplete";
import CancellationConfirm from "./components/CancellationConfirm";
import ConfirmationList from "./components/ConfirmationList";
import Dashboard from "./components/Dashboard";
import ReceptionCarNum from "./components/ReceptionCarNum";
import ReceptionCarNumber from "./components/ReceptionCarNumber";
import ReceptionCarType from "./components/ReceptionCarType";
import ReceptionComplete from "./components/ReceptionComplete";
import ReceptionConfirm from "./components/ReceptionConfirm";
import ReceptionDate from "./components/ReceptionDate";
import ReceptionInspectionType from "./components/ReceptionInspectionType";

import Layout from "./components/Layout";
import Login from "./components/Login";
import RequireAuth from "./RequireAuth";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="reception_car_type" element={<ReceptionCarType />} />
          <Route
            path="reception_inspection_type"
            element={<ReceptionInspectionType />}
          />
          <Route path="reception_date" element={<ReceptionDate />} />
          <Route path="reception_car_num" element={<ReceptionCarNum />} />
          <Route path="reception_car_number" element={<ReceptionCarNumber />} />
          <Route path="reception_confirm" element={<ReceptionConfirm />} />
          <Route path="reception_complete" element={<ReceptionComplete />} />
          <Route path="confirmation_list" element={<ConfirmationList />} />
          <Route
            path="cancellation_confirm/:reservationId"
            element={<CancellationConfirm />}
          />
          <Route
            path="cancellation_complete/:reservationId"
            element={<CancellationComplete />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
