import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant";

export default function ReceptionInspectionType(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionInspectionTypeComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ReceptionInspectionTypeComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      inspection_type: this.cookies.inspection_type,
    };
  }

  nextOnClick = async (event) => {
    if (!this.state.inspection_type) {
      alert("検査の種類を選択してください");
      return false;
    }
    this.setCookie("inspection_type", this.state.inspection_type);
    this.props.navigate("/reception_date");
  };

  render() {
    console.log("render()");
    console.log(this.state.inspection_type);
    return (
      <div class="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h3 class="mb-0">予約受付</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section class="content">
          <div class="container-fluid">
            <div class="card card-default">
              <div class="card-body">
                <div class="row pb-4">
                  <div class="col-12">
                    <label>検査の種類を選択してください。</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div
                        class="btn-group-toggle"
                        data-toggle="buttons"
                        value={this.state.inspection_type}
                        onChange={(event) => {
                          this.setState({
                            inspection_type: event.target.value,
                          });
                        }}
                      >
                        <label
                          id="inspection_type_1"
                          className={
                            this.state.inspection_type ==
                            Constant.INSPECTION_TYPE_1
                              ? "btn btn-block btn-outline-primary focus active"
                              : "btn btn-block btn-outline-primary"
                          }
                        >
                          <input
                            type="radio"
                            name="inspection_type"
                            value="1"
                            autocomplete="off"
                            checked={
                              this.state.inspection_type ==
                              Constant.INSPECTION_TYPE_1
                                ? true
                                : false
                            }
                          />
                          継続・構造変更
                        </label>
                        <br />
                        <label
                          id="inspection_type_2"
                          className={
                            this.state.inspection_type ==
                            Constant.INSPECTION_TYPE_2
                              ? "btn btn-block btn-outline-primary focus active"
                              : "btn btn-block btn-outline-primary"
                          }
                        >
                          <input
                            type="radio"
                            name="inspection_type"
                            value="2"
                            autocomplete="off"
                            checked={
                              this.state.inspection_type ==
                              Constant.INSPECTION_TYPE_2
                                ? true
                                : false
                            }
                          />
                          中古新規
                        </label>
                        <br />
                        <label
                          id="inspection_type_3"
                          className={
                            this.state.inspection_type ==
                            Constant.INSPECTION_TYPE_3
                              ? "btn btn-block btn-outline-primary focus active"
                              : "btn btn-block btn-outline-primary"
                          }
                        >
                          <input
                            type="radio"
                            name="inspection_type"
                            value="3"
                            autocomplete="off"
                            checked={
                              this.state.inspection_type ==
                              Constant.INSPECTION_TYPE_3
                                ? true
                                : false
                            }
                          />
                          新車新規
                        </label>
                        <br />
                        <label
                          id="inspection_type_4"
                          className={
                            this.state.inspection_type ==
                            Constant.INSPECTION_TYPE_4
                              ? "btn btn-block btn-outline-primary focus active"
                              : "btn btn-block btn-outline-primary"
                          }
                        >
                          <input
                            type="radio"
                            name="inspection_type"
                            value="4"
                            autocomplete="off"
                            checked={
                              this.state.inspection_type ==
                              Constant.INSPECTION_TYPE_4
                                ? true
                                : false
                            }
                          />
                          マルチュウ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer pt-2 pb-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <button
                      type="button"
                      class="btn btn-default"
                      onClick={() => {
                        this.props.navigate("/reception_car_type");
                      }}
                    >
                      戻る
                    </button>
                    <>&nbsp;&nbsp;</>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={this.nextOnClick}
                    >
                      次へ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
