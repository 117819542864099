import { API, Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import Constant from "../Constant";

export default function ReceptionCarNum(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionCarNumComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ReceptionCarNumComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.state = {
      api_name: config.stage,
      car_num: this.cookies.car_num,
    };
  }

  checkVacant = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        car_type: this.cookies.car_type,
        inspection_type: this.cookies.inspection_type,
        reservation_date: this.cookies.reservation_date,
        round: this.cookies.round,
        car_num: this.state.car_num,
      },
    };

    await API.get(this.state.api_name, "/reservations/vacant", request)
      .then((r) => {
        console.log(r);
        if (!r) {
          alert("予約に空きがありません。");
          this.props.navigate("/reception_date");
          return false;
        }
        this.setCookie("car_num", this.state.car_num);
        if (
          this.cookies.site_id != Constant.SITE_ID_1 ||
          this.cookies.inspection_type == Constant.INSPECTION_TYPE_4
        ) {
          this.props.navigate("/reception_confirm");
        } else {
          this.props.navigate("/reception_car_number");
        }
      })
      .catch((e) => {
        toast.error("予約のチェックエラー");
      });
  };

  nextOnClick = async (event) => {
    if (!this.state.car_num) {
      alert("希望の台数を選択してください");
      return false;
    }
    // 予約可チェック
    this.checkVacant();
  };

  render() {
    console.log("render()");
    console.log(this.state.car_num);
    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Toaster />
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約受付</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>希望の台数を選択してください。</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div
                          className="btn-group-toggle"
                          data-toggle="buttons"
                          value={this.state.car_num}
                          onChange={(event) => {
                            this.setState({
                              car_num: event.target.value,
                            });
                          }}
                        >
                          <label
                            id="car_num_1"
                            className={
                              this.state.car_num == Constant.CAR_NUM_1
                                ? "btn btn-block btn-outline-primary focus active"
                                : "btn btn-block btn-outline-primary"
                            }
                          >
                            <input
                              type="radio"
                              name="car_num"
                              value="1"
                              autocomplete="off"
                              checked={
                                this.state.car_num == Constant.CAR_NUM_1
                                  ? true
                                  : false
                              }
                            />
                            1台
                          </label>
                          <br />
                          <label
                            id="car_num_2"
                            className={
                              this.state.car_num == Constant.CAR_NUM_2
                                ? "btn btn-block btn-outline-primary focus active"
                                : "btn btn-block btn-outline-primary"
                            }
                          >
                            <input
                              type="radio"
                              name="car_num"
                              value="2"
                              autocomplete="off"
                              checked={
                                this.state.car_num == Constant.CAR_NUM_2
                                  ? true
                                  : false
                              }
                            />
                            2台
                          </label>
                          <br />
                          <label
                            id="car_num_3"
                            className={
                              this.state.car_num == Constant.CAR_NUM_3
                                ? "btn btn-block btn-outline-primary focus active"
                                : "btn btn-block btn-outline-primary"
                            }
                          >
                            <input
                              type="radio"
                              name="car_num"
                              value="3"
                              autocomplete="off"
                              checked={
                                this.state.car_num == Constant.CAR_NUM_3
                                  ? true
                                  : false
                              }
                            />
                            3台
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/reception_date");
                        }}
                      >
                        戻る
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onclick="clickNext()"
                        onClick={this.nextOnClick}
                      >
                        次へ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </>
    );
  }
}
