const Constant = {
  SITE_ID_1: 1,
  SITE_ID_2: 2,
  SITE_ID_3: 3,
  CAR_TYPE_1: 1,
  CAR_TYPE_2: 2,
  CAR_TYPE_LIST: {
    1: "陸運事務所",
    2: "軽自動車",
  },
  ROUND_1: 1,
  ROUND_2: 2,
  ROUND_3: 3,
  ROUND_4: 4,
  ROUND_5: 5,
  ROUND_LIST: {
    1: "1R",
    2: "2R",
    3: "3R",
    4: "4R",
    5: "5R",
  },
  INSPECTION_TYPE_0: 0,
  INSPECTION_TYPE_1: 1,
  INSPECTION_TYPE_2: 2,
  INSPECTION_TYPE_3: 3,
  INSPECTION_TYPE_4: 4,
  INSPECTION_TYPE_LIST: {
    0: "",
    1: "継続・構造変更",
    2: "中古新規",
    3: "新車新規",
    4: "マルチュウ",
  },
  CAR_NUM_1: 1,
  CAR_NUM_2: 2,
  CAR_NUM_3: 3,
  CAR_NUM_LIST: {
    1: "1台",
    2: "2台",
    3: "3台",
  },
  TRIANGLE_NUM: 10,
};
export default Constant;
