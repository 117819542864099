import "@aws-amplify/ui-react/styles.css";
import "./login.css";

import {
  Authenticator,
  Button,
  Heading,
  Text,
  useAuthenticator,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

I18n.setLanguage("ja");
I18n.putVocabularies({
  ja: {
    "Sign in": "ログイン",
    Submit: "再登録",
    "Signing in": "ログイン中...",
    "Resend Code": "認証コードを再送する",
    "User does not exist.": "ユーザーが存在しません",
    "Incorrect username or password.": "ログインIDまたはパスワードが違います",
    "User is not confirmed.": "ユーザーは検証されていません",
    "User already exists": "ユーザーは既に存在します",
    "Invalid verification code provided, please try again.":
      "指定された認証コードが無効です。もう一度お試しください",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Account recovery requires verified contact information":
      "アカウントの復旧には認証済みの連絡先情報が必要です",
    "Invalid phone number format":
      "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Username cannot be empty": "ログインIDは必須です",
    "Password attempts exceeded": "ログイン試行回数が上限に達しました",
    "Attempt limit exceeded, please try after some time.":
      "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Username/client id combination not found.": "ユーザーが存在しません",
    "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です",
    "Password does not conform to policy: Password not long enough":
      "パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have uppercase characters":
      "パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have lowercase characters":
      "パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have numeric characters":
      "パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
    "Temporary password has expired and must be reset by an administrator.":
      "一時パスワードは無効です。管理者によるリセットが必要です",
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
      "入力チェックエラー、必須項目がNULLです",
    "Invalid code received for user": "無効なコードです",
    "Invalid session for the user, session is expired.":
      "無効なセッション、セッションは有効期限切れです。ログインからやり直してください",
    "Send code": "メールを送信",
    "Back to Sign In": "戻る",
  },
});

export default function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            className="card-header text-center card card-primary"
            padding={`.75rem 1.25rem`}
            style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
            level={4}
          >
            車検予約システム
            <br />
            会員マイページ
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="left">
            <Button
              fontWeight="bold"
              onClick={toResetPassword}
              size="normal"
              variation="link"
            >
              パスワードを忘れた方はこちら
            </Button>
          </View>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <Heading
              className="card-header text-center card card-primary"
              padding={`.75rem 1.25rem`}
              style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
              level={4}
            >
              車検予約システム
              <br />
              会員マイページ
            </Heading>
            <Text>
              登録したログインID（メールアドレス）を入力してください。
              <br />
              パスワード再発行ページで必要な認証コードをメールでお送りします。
            </Text>
          </>
        );
      },
      Footer() {
        return <></>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            className="card-header text-center card card-primary"
            padding={`.75rem 1.25rem`}
            style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
            level={4}
          >
            車検予約システム
            <br />
            会員マイページ
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        label: "ログインID（メールアドレス）",
        placeholder: "",
      },
      password: {
        labelHidden: false,
        label: "パスワード",
        placeholder: "",
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        label: "ログインID（メールアドレス）",
        placeholder: "",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        placeholder: "認証コードを入力",
        label: "認証コード",
        isRequired: false,
      },
      password: {
        labelHidden: false,
        label: "新しいパスワード",
        placeholder: "",
      },
      confirm_password: {
        labelHidden: false,
        label: "新しいパスワード（確認用）",
        placeholder: "",
      },
    },
  };

  return (
    <div className="login-page" style={{ minHeight: "470px" }}>
      <div className="login-box">
        <View className="auth-wrapper">
          <Authenticator
            formFields={formFields}
            components={components}
            hideSignUp={true}
          ></Authenticator>
        </View>
      </div>
    </div>
  );
}
